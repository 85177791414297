"use strict";

var App = {
  init: function init() {
   /*INTRO*/
		
		     $('.diapo:first-child img').velocity({opacity: 1},{delay:500, duration: 1500 });
			      $('.diapo:nth-child(2) img').velocity({opacity: 1},{delay:1500, duration: 1500 });
			      $('.diapo:nth-child(3) img').velocity({opacity: 1},{delay:2500, duration: 1500 });
			      $('.diapo:nth-child(4) img').velocity({opacity: 1},{delay:3500, duration: 1500 });
			      $('.diapo:nth-child(5) img').velocity({opacity: 1},{delay:4500, duration: 1500 });
			      $('.diapo:nth-child(6) img').velocity({opacity: 1},{delay:5500, duration: 1500 });
			   

			      $('.diapo img').velocity({opacity: 0.2},{delay:1500, duration: 1500 });
			      $('#text-intro').velocity({top: 0},{ delay:6500, duration: 2500 });


			      $('.btn-pass').click(function(){
			      		
			      		$('.diapo:first-child img').velocity('finish');
				        $('.diapo:nth-child(2) img').velocity('finish');
				        $('.diapo:nth-child(3) img').velocity('finish');
				        $('.diapo:nth-child(4) img').velocity('finish');
				        $('.diapo:nth-child(5) img').velocity('finish');

				        $('.diapo img').velocity('finish');
			      		$('#text-intro').delay(0).velocity('finish');
			      		$(this).velocity({ opacity: 0 }, { display: "none" });
			      });
		
  }
};

module.exports = App;
