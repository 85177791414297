"use strict";

var App = {
  init: function init() {
   /*flexslider*/
		
		     $(window).load(function() {
		          $('.flexslider').flexslider({
		                animation: "slide",
		                slideshow: true,
		                slideshowSpeed: 8000,
		                initDelay: 8000,
		                pauseOnHover: true,
		                controlsContainer: $(".custom-controls-container"),
	    				customDirectionNav: $(".custom-navigation a")

		          });
		     });
		
  }
};

module.exports = App;