"use strict";

var App = {
  init: function init() {
   /**********/
		
		     var pgurl = window.location.href.substr(window.location.href.lastIndexOf("/")+1);
		     $("#nav .navbar-nav li a").each(function(){
		          if($(this).attr("href") == pgurl || $(this).attr("href") == '' )
		          $(this).addClass("active");
		     });
	/**********/	
  }
};

module.exports = App;