"use strict";

var App = {
  init: function init() {
   /*flexslider-histo*/
		
		     $(window).load(function() {
	          $('.flexslider').flexslider({
	                animation: "slide",
	                slideshow: true,
	                slideshowSpeed: 8000,
	                directionNav: false

	          });
	        });
		
  }
};

module.exports = App;


