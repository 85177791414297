"use strict";

var App = {
  init: function init() {
   /*GMAPS*/
		
		     $(window).bind("load", function() {
	       
	    
	            var image = 'img/map-marker.png';
	            var mapOptions = {
	                zoom: 6,
			        zoomControl : true,
			        scrollwheel: false,
			        navigationControl: false,
			        mapTypeControl: true,
			        draggable: true,
			        zoomControlOpt: {
			          style : 'SMALL',
			          position: 'TOP_LEFT'
			        },
	        		panControl : false,
	                center: new google.maps.LatLng(45.740693, -0.675659),
	                mapTypeId: google.maps.MapTypeId.ROADMAP
	            }
	            var map = new google.maps.Map(document.getElementById('map'), mapOptions);
	            var locations = [
	            	['Les Charentes en Boutique', 45.572818 , -0.641634 ],
	            	['Domaine du Seudre', 45.50878 , -0.685263 ],
	            	['Epices & Tout', 45.692524 , -1.052517 ],
	            	['Musée des Arts du Cognac', 45.694948 , -0.33352 ],
	            	['Pâtisserie Hue « Parfums sucrés »', 45.648927 , 0.155366 ],
	            	['Ô COFFEE TEA', 44.6330567 , -1.1509598 ],
	            	['La Boutique des Saveurs', 48.461997 , 7.483172 ],
	            	['Rose & Léon', 47.898856 , 1.906448 ],
	            	['International Froggy Gourmet Store', 43.582351 , 7.127679 ],
	            	['Le Fournil de Stéphane', 45.5694894 , -0.6754657 ],
	            	['Hôtel Restaurant LE LION D’OR', 45.5677618 , -0.6769806 ],
	            	['La Maison de la Vigne et des Saveurs (MVS)', 45.5287595 , -0.3077109 ]
	            	['Boutique THIERRY JULLION', 45.4451637 , -0.4365813 ]
	            ]


			    var infowindow = new google.maps.InfoWindow();

			    var marker, i;

			    for (i = 0; i < locations.length; i++) {  
			      marker = new google.maps.Marker({
			        position: new google.maps.LatLng(locations[i][1], locations[i][2]),
			        map: map,
			        icon: image
			      });

			      google.maps.event.addListener(marker, 'click', (function(marker, i) {
			        return function() {
			          infowindow.setContent(locations[i][0]);
			          infowindow.open(map, marker);
			        }
			      })(marker, i));
			    }



	       
	      });
		
  }
};

module.exports = App;

