"use strict";

var App = {
  init: function init() {
   /*HOVER*/
		
		     $( ".actuChef figcaption" ).hover(
					  function() {
					    $(this).velocity({opacity: 1, duration: 200 });
					  }, function() {
					    $(this).velocity({opacity: 0, duration: 100 });
					  }
				);
		
  }
};

module.exports = App;
